import React from 'react';
import LandingPageView2 from 'views/LandingPageView2';
import Main from 'layouts/Main';
import WithLandingPageLayout from 'WithLandingPageLayout';
import { Helmet } from 'react-helmet';

// DATA

const title = 'Save on Google Cloud Storage Pricing';
const content = {
  hero: {
    title: "Google Cloud Storage Discounts",
    emphasized: "Save 60% With One Click",
    subtitle: "WindRate can immediately lower your GPC storage costs.",
    titleVariant: "h2",
    subtitleVariant: "h4",      
  },
  quoteForm: {
    quote_provider:"google",
    title: "Lower GCP Storage Costs!",  
    subtitle: "Leading providers bid for your business!",
    titleVariant: "h4",
    subtitleVariant: "h5",
    refPage: "google-cloud-storage-savings",   
  },
  subText: {
    title: "Same Google Cloud Storage",
    emphasized: "but cheaper",
    subtitle: "<br><b>Get great pricing per TB on Google Gloud Standard, Coldline, Nearline, and Archive storage.</b><br><br>Compare GCP vs AWS and GCP vs Azure in a single click.<br><br>We will take your requirements and source competitive quotes from leading partners and Managed Service Providers, saving you up to 60% on the same Google Cloud storage you use today. You decide who to deal with and when, your information stays anonymous in the meantime.<br>",
    subtitleEmphasized: "<a href='/get-a-quote'>Request a quote today, it’s simple and free!</a>",
    titleVariant: "h5",
    subtitleVariant: "h6",     
  }
};

// END DATA

const LandingPage = () => {
  return (
  <>
  <Helmet
    defaultTitle="WindRate | #1 Cloud Storage Pricing Engine"
    title={title}
    titleTemplate="%s | WindRate.com"
  />     
    <WithLandingPageLayout
      data={content}
      component={LandingPageView2}
      layout={Main}
    />
    </>
  )
};
 
 export default LandingPage;
 